.flex {
    display: flex;
}

.gap16 {
    gap: 16px;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-column-gap16 {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.flex-space-around-block {
    height: -webkit-fill-available;
    justify-content: space-between;
}

.w100 {
    width: 100%;
}

.w100px {
    width: 100px;
}

.input-label {
    display: flex;
    font-family: "Montserrat-Medium";
    font-size: 14px;
    margin-bottom: 16px;
    color: #5C5C5C;
    gap: 6px;
    align-items: center;
}
.site_optional {
    font-family: "Montserrat-Light";
    font-size: 12px;
    font-style: italic;
    color: #5c5c5c;
}
.site_txt {
    font-family: "Montserrat-Medium";
    font-size: 14px;
    margin: 0px;
    padding: 0px;
    line-height: 17px;
    margin-bottom: 4px;
    color: #5C5C5C;
}
.site_txt2 {
 font-family: "Montserrat-Regular";
    font-size: 14px;
    margin: 0px;
    padding: 0px;
     line-height: 17px;
     color: #99999C;
}
.mb16 {
    margin-bottom: 16px;
}
.site_lane_count {
    display: flex;
    gap: 32px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
}

.iconWithTxt {
    display: flex;
}
.gap6 {
    gap: 6px;
}
.gap12 {
    gap: 12px;
}