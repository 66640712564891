// Service In Progress CSS Start Here

.reparisInProgressContainer {
    display: flex;
    gap: 16px;
    margin: 16px 0px;
    width: 100%;
    justify-content: center;
}

.rightContentSection {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 240px;
}

.barsContainer {
    display: flex;
    gap: 8px
}

.serviceIcon {
    width: 32px;
    height: 32px;
}

.reparisInProgressTitle {
    display: flex;
    justify-content: space-between;
    color: #5C5C5C;
    font-size: 12px;
    font-weight: 500;
    gap: 4px
}

.barsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .stepBar {
        background-color: #ccc;
        height: 6px;
        border-radius: 4px;
    }

    .stepBarActive {
        background-color: #99999C;
        height: 6px;
        border-radius: 4px;
    }
}

.reparisInProgressText {
    color: #B5B5B6;
    font-size: 10px;
}

.inServiceDateTime {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #5C5C5C
}

.inServiceStatusText {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #474747;
}

// Service In Progress CSS Ends Here