.catVideo {
  /* width: 60vw;
  max-width: 990px; */
   height: calc(100vh - 224px);
  overflow: auto;
}

.videoContainer {
  height: calc(100vh - 240px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px 0px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.iframeForOperatorsGuide{
  background-color: transparent !important;
}