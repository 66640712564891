// .dashboard-container {
//   position: relative;
//   font-family: Arial, sans-serif;
//   background: #f7f8fa;
//   margin-left: 27px;
//   margin-right: 27px;
//   margin-top: 30px;
//   margin-bottom: 21px;
//   /* margin: 0; */
//   /* padding: 0; */
// }
// .dashboard-container .header-div {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 20px;
// }
// .dashboard-container .stats-overview {
//   display: grid;
//   grid-template-columns: repeat(4, 1fr);
//   gap: 20px;
// }
// .dashboard-container .stat-card {
//   background: white;
//   padding: 12px;
//   border-radius: 8px;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   display: flex;
//   /* align-items: center; */
//   flex-direction: column;
//   height: 101px;
//   justify-content: space-evenly;
// }
// .dashboard-container .stat-card .icon {
//   font-size: 30px;
//   margin-right: 10px;
// }
// .dashboard-container .main-content {
//   display: flex;
//   gap: 20px;
//   margin-top: 20px;
// }
// .dashboard-container .customer-usage {
//   /* flex: 1; */
//   padding: 15px;
// }
// .dashboard-container .chart-container {
//   flex: 1;
//   /* background: white; */
//   padding: 15px;
//   border-radius: 8px;
//   /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
// }
// .dashboard-container table {
//   width: 100%;
//   border-collapse: collapse;
//   margin-top: 10px;
// }
// .dashboard-container th,
// .dashboard-container td {
//   padding: 10px;
//   border-bottom: 1px solid #ddd;
// }
// .dashboard-container .tickets {
//   padding: 15px;
//   border-radius: 8px;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   background: white;
//   height: 100%;
//   /* width: 24% !important; */
// }
// .dashboard-container .ticket-list {
//   display: flex;
//   flex-direction: column;
//   gap: 10px;
// }
// .dashboard-container .ticket {
//   padding: 10px;
//   background: #f7f7f7;
//   border-radius: 5px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
// }
// .dashboard-container .status {
//   font-size: 12px;
//   color: white;
//   padding: 3px 6px;
//   border-radius: 5px;
// }
// /* .dashboard-container .open {
//   background: #ffebee;
// }
// .dashboard-container .in-progress {
//   background: #fff3e0;
// }
// .dashboard-container .resolved {
//   background: #e8f5e9;
// } */
// .dashboard-container .usage-graph {
//   display: flex;
//   gap: 16px;
//   flex: 1;
//   /* background: white; */
//   padding: 15px;
//   /* border-radius: 8px; */
//   /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
// }

// .dashboard-container .icon-class {
//   position: relative;
//   top: 10px;
//   left: 10px;
//   width: 24px; /* Adjust size */
//   height: 24px; /* Adjust size */
// }
// .cardImageTitle {
//   display: flex;
//   justify-content: flex-start;
//   /* align-items: center; */
// }
// .countLastMonth {
//   display: flex;
//   justify-content: space-between;
//   align-items: end;
//   /* align-items: ; */
// }

// .cardTitle {
//   margin-left: 10px;
//   font-size: 14px !important;
//   font-family: 'Montserrat-regular', sans-serif;
//   color: #99999c;
// }
// .headerTitle {
//   font-size: 29px !important;
//   font-family: 'Montserrat-regular', sans-serif;
//   color: #000000;
// }
// .dateTitle {
//   font-size: 17px !important;
//   font-family: 'Montserrat-regular', sans-serif;
//   color: #000000;
// }
// .cardCount {
//   font-size: 36px !important;
//   font-family: 'Montserrat-regular', sans-serif;
//   color: #333333;
// }
// .lastMonthCount {
//   font-size: 10px !important;
//   font-family: 'Montserrat-regular', sans-serif;
//   color: #b5b5b6;
//   display: flex;
//   gap: 2px;
// }
// .lastMonthCountValue {
//   font-size: 10px !important;
//   font-family: 'Montserrat-regular', sans-serif;
//   color: #b5b5b6;
// }
// .usage-graph-customer-usage {
//   border-radius: 8px;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   background: white;
//   height: 310px;
// }
// .yourFleetUsage {
//   background: white;
//   border-radius: 5px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   width: 100%;
//   height: 212px;
// }
// .yourFleetHealth {
//   /* height: 100%; */
// }

// .dashboard-container .tableEmpty {
//   min-height: 0px !important;
// }

// .customerUsageTitle {
//   font-size: 14px !important;
//   font-family: 'Montserrat-regular', sans-serif;
//   color: #333333;
//   line-height: 34px;
// }
// .cropType {
//   width: 120px !important;
//   float: right !important;
//   margin-right: -10px;
// }
// .customer-usage-title {
//   display: flex;
//   justify-content: space-between;
//   margin: 12px 16px;
//   align-items: center;
// }

// .customer-usage-title .filters_card {
//   padding: 0px !important;
//   border-bottom: none !important;
// }

// .customer-usage-table {
//   height: 100%;
//   width: 100%;
//   margin: 0px 16px;
// }
// .customer-usage-table .ant-table-thead > tr > th {
//   color: #b5b5b6 !important;
//   font-size: 12px !important;
//   font-family: 'Montserrat-regular', sans-serif;
// }

// .chartContent {
//   margin: 0px 16px;
//   display: flex;
//   gap: 12px;
//   justify-content: space-evenly;
// }

// .chartItem {
//   width: 146px;
//   display: flex;
//   align-items: center;
//   padding: 10px;
//   /* background-color: #f4f4f4; */
//   /* background: #f7f7f7; */
// }
// .chartTitle {
//   font-size: 12px !important;
//   font-family: 'Montserrat-regular', sans-serif;
//   color: #474747;
//   font-weight: 400;
// }
// .chartValue {
//   font-size: 12px !important;
//   font-family: 'Montserrat-regular', sans-serif;
//   color: #000000;
//   font-weight: 600;
// }

// .ticketsTitle {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
// }
// .ticket-status-btn {
//   display: flex;
//   height: 24px;
//   align-items: center;
//   justify-content: center;
//   flex-direction: row;
//   padding: 4px;
//   gap: 6px;
//   margin: 8px;
//   border-radius: 5px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   background: #ffffff;
//   border: 1px solid #eeeeee;
// }

// .yourFleetHealth {
//   display: flex;
//   justify-content: space-between;
//   flex-direction: column;
//   align-items: center;
//   background: #ffffff;
//   width: 100%;
//   border-radius: 8px;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   background: white;
//   height: 212px;
// }

// .yourFleetHealth .filters_card {
//   padding: 0px !important;
//   border-bottom: none !important;
// }
// .yourFleetHealthUsage {
//   display: flex;
//   flex-direction: row;
//   gap: 10px;
//   grid-gap: 10px;
//   justify-content: center;
//   align-items: center;
//   margin-top: 20px;
// }
// .yourFleetHealthTitle {
//   display: flex;
//   justify-content: space-between !important;
//   align-items: center;
//   gap: 10px;
//   flex-direction: row;
//   width: 100%;
//   padding: 10px;
// }

// .chartAndLevel {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   gap: 10px;
//   flex-direction: row;
//   height: 100%;
//   width: 100%;
//   padding: 10px;
// }

// .chartLevel {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: flex-start;
//   align-self: stretch;
// }
// .chartOnly {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   align-self: stretch;
// }
// .activeError {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   padding: 10px;
//   gap: 10px;
// }
// .threeFlags {
//   width: 33px;
// }
// .levelItem {
//   width: 100%;
//   margin: 2px;
//   display: flex;
//   gap: 10px;
// }
// .error-list {
//   display: flex;
//   flex-direction: column;
//   gap: 10px;
// }

// .customerUsageHead {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   width: 100%;
// }

// .metrics {
//   display: flex;
//   justify-content: space-between;
// }
// .metric {
//   flex-grow: 1;
// }

// .metricSection {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
// }

// .ticket-Header {
//   display: flex;
//   /* flex-direction: column; */
//   justify-content: space-between;
//   align-items: center;
// }

// .ticketDescription {
//   display: flex;
//   justify-content: space-between;
// }
// .ticketDescriptionOnly {
// }


.dashboardContainer {
  padding: 24px 27px;
  overflow-y: auto;
  height: calc(100vh - 0px);
}

.ms_timeStamp {
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  line-height: 17px;
}

.headHomeTxt {
  font-size: 24px;
  font-family: 'Montserrat-SemiBold';
  line-height: 29px;
}

.ms_dashboardHead {
  min-height: 30px;
  display: flex;
  justify-content: space-between;
}

.ms_dashboardWidgetCard {
  background-color: #fff;
  min-height: 101px;
  border-radius: 8px;
  border: 1px solid #EEEEEE;
  padding: 12px;
  display: flex;
}

.ms_dashboardCard {
  background-color: #fff;
  min-height: 101px;
  border-radius: 8px;
  border: 1px solid #EEEEEE;
  padding: 12px;
  position: relative;
}

.ms_CardRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.ms_CardColumn {
  display: flex;
  gap: 9px;
  align-items: center;

}

.ms_CardColumn2 {
  display: flex;
  gap: 9px;
  align-items: flex-end;
  justify-content: space-between;
}

.ms_CardColumn3 {
  display: flex;
  gap: 9px;
  align-items: center;
  justify-content: space-between;
}

.alignItemStart {
  align-items: flex-start !important;
}

.ms_CardTitle {
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  line-height: 17px;
  color: #99999C;
}

.ms_CardCount {
  font-size: 36px;
  font-family: 'Montserrat-SemiBold';
  line-height: 38px;
  color: #333333;
}

.ms_CardIndex {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 10px;
  font-family: 'Montserrat-Regular';
  color: #B5B5B6;
}

.ms_CardIncTxt {
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  line-height: 12px;
}

.incGreen {
  color: #379526
}

.decRed {
  color: #D43D38
}

.dashboardContainer .ant-row {
  row-gap: inherit !important;
}

// .dashboardContainer .ant-row > div {
//   margin-top: 10px;
//   margin-bottom: 10px;
// }
.flexDirection {
  flex-direction: column;
}

.ms_customerUsage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ms_blockHead {
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  line-height: 17px;
  color: #333333;
}

.imgMaxWidth {
  max-width: 100%;
  width: 100%;
}

.ms_status {
  display: flex;
  gap: 6px;
  align-items: center;
  background-color: #fff;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  padding: 4px 10px;
  min-height: 24px;
  color: #B5B5B6;
  cursor: pointer;
  font-size: 10px;
  font-family: 'Montserrat-Medium';
}

.ms_ticket_card {
  background-color: #F7F7F7;
  border-radius: 10px;
  padding: 8px 10px;
  min-height: 92px;
  display: flex;
  margin: 8px 0px;
}

.ms_status_chip {
  border-radius: 25px;
  padding: 3px 10px;
  font-size: 8px;
  font-family: 'Montserrat-Regular';
  min-height: 16px;
}

.ms_ticket_no {
  display: flex;
  gap: 4px;
  align-items: center;
}

.ms_round {
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  text-transform: uppercase;
  font-size: 8px;
  font-family: 'Montserrat-SemiBold';
  color: #fff;
}

.ms_circle_green {
  background-color: #366376;
}

.ms_circle_orange {
  background-color: #F5DFC3;
}

.ms_ticket_dtls {
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  color: #000;
}

.ticket_open {
  background-color: #F4E8E6;
  color: #DE827A;
}

.ticket_inprogress {
  background-color: #F6EAD8;
  color: #EB9223;
}

.ms_ticket_desc {
  font-size: 10px;
  font-family: 'Montserrat-Regular';
  color: #1F1F1F;
  margin-bottom: 2px;
}

.ms_ticket_loc_date {
  font-size: 8px;
  font-family: 'Montserrat-Regular';
  color: #5C5C5C;
  display: flex;
  gap: 4px;
  align-items: center;
}

.ms_dot {
  background-color: #B5B5B6;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  display: flex;
}

.ms_ticket_flags {
  display: flex;
  gap: 6px;
  align-items: center;
}


.ms_tickets_container {
  overflow-y: auto;
  height: calc(100vh - 293px);
  margin-right: -10px;
  padding-right: 5px;
  overflow-x: hidden;
}

.fh_active_errors_sec {
  display: grid;
  grid-template-columns: 42% 54%;
  gap: 20px;
  align-items: center;
}

.fh_error_code_title {
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  color: #333333;
  margin: 0;
  padding: 0;
}

.fh_error_code_count {
  font-size: 20px;
  font-family: 'Montserrat-SemiBold';
  color: #333333;
  margin: px;
  padding: 0px;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eeeeee;
}

.fh_error_codes {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.fh_error_item {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-family: 'Montserrat-Medium';
}

.fh_error_flags {
  display: flex;
  gap: 4px;
  align-items: center;
}

.fh_f10 {
  font-size: 10px !important;
}

.fh_chart_sec {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fh_min_height {
  min-height: 255px;
  height: 100%;
}

.mtb0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.dlDashboardContainer {
  padding: 32px 27px 21px 27px;
  height: 100vh;
  overflow-y: auto;
}

.mb23 {
  margin-bottom: 23px;
}

.mh24 {
  min-height: 24px;
  display: flex;
  align-items: center;
}

.mh32 {
  min-height: 32px;
  display: flex;
  align-items: center;
}

.fleet_usage_sec {
  display: grid;
  grid-template-columns: 55% 41%;
  gap: 20px;
  align-items: center;
}

.fleet_drivemode_txt {
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  color: #5C5C5C;
  line-height: 15px;
}

.fleethrs_bold {
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  color: #333333;
  padding-left: 5px;
  padding-right: 5px;
}

.fleet_drivemode_sec {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding-left: 30px;
  padding-right: 30px;
}

.fleet_drivemode_item {
  display: flex;
  flex-direction: column;
  border-left: 3px solid #67AFB7;
  padding-left: 10px;
  gap: 6px;
  min-width: 40%;
}

.fleet_drivemode_type {
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  color: #5C5C5C;
}

.cropType {
  min-width: 100px;
}

.dealer-customer-table {
  .ant-table-thead>tr>th {
    padding: 8px;
    font-size: 14px;
    height: inherit !important;
  }

  .ant-table-tbody>tr>td {
    padding: 8px;
    font-size: 12px;
    height: inherit;
  }
}

.msBarChart {
  margin-bottom: 10px;
  // height: 180px;
  // min-height: 180px;
}

.operation_cards_sec {
  display: flex;
  gap: 12px;
}

.operation_card {
  background-color: #F7F7F7;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  gap: 10px;
  min-height: 49px;
  flex: 1;
}

.operation_card_title {
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  line-height: 15px;
}

.operation_card_value {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  line-height: 17px;
}

.operation_card_dtls {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.operation_card img {
  width: 30px;
  height: 30px;
}

.dp_msBarChart {
  // height: calc(100vh - 50vh);
  overflow-y: hidden;
  overflow-x: hidden;
  // min-height:203px;
  // max-height: 24vh;
  margin-bottom: 5px;
  // margin-left: 12px;
  // margin-right: 12px;
}

.ms_dashboardCard .filters_card .float-label {
  margin-right: 0px !important;
}

.dealer-customer-table .ant-table-tbody>tr>td {
  color: #5C5C5C !important;
}

.dealer-customer-table .infinite-scroll-component {
  height: auto !important;
  min-height: calc(100vh - 590px);
  max-height: calc(100vh - 590px);
}

.dealer-customer-table div#total {
  top: 0px !important;
}

.dealer-customer-table .ant-table-thead>tr>th {
  border-bottom: none !important;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .dealer-customer-table .infinite-scroll-component {
    height: auto !important;
    min-height: calc(100vh - 300px);
    max-height: calc(100vh - 300px);
  }

  .ms_tickets_container {
    height: calc(100vh - 150px);
  }
}

@media (min-width: 1366px) and (max-width: 1600px) {
  .dealer-customer-table .infinite-scroll-component {
    height: auto !important;
    min-height: calc(100vh - 590px);
    max-height: calc(100vh - 590px);
  }
}

@media (min-width: 1200px) and (max-width: 1365px) {
  .dealer-customer-table .infinite-scroll-component {
    height: auto !important;
    min-height: calc(100vh - 424px);
    max-height: calc(100vh - 424px);
  }
}

.dealer-customer-table .ant-table-body .ant-table-cell {
  padding-right: 10px !important;
}

#dealersOperationalHours {
  width: 100%;
  min-height: 201px;
  height: calc(100vh - 620px);
  /* Adjust as needed */
}

/* chart no data */

.ddNodata {
  display: flex;
  min-height: 208px;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 615px);
}

.ddNodataWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.ddNodatatxt {
  font-size: 16px;
  color: #333;
  font-family: 'Montserrat-Regular';
}

.reviewTickets {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  color: #99999C;
  text-align: center;
}

.reviewTicketsBlock {
  position: absolute;
  bottom: 0;
  padding: 16px;
  display: flex;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  background-color: #ffffff;
}

/* chart no data*/

@media (min-height: 600px) and (max-height: 768px) {
  .dealer-customer-table .infinite-scroll-component {
    min-height: 232px !important;
  }
}