.btnSaveCSV {
  position: absolute;
  bottom: 40px;
  left: 48px;
}

.customdbWidget {
  margin-bottom: 0px !important;
}

.charging-container {
  max-width: 150px;
  width: 100%;
  height: 10px;
  border: 1px solid #474747;
  border-radius: 2px !important;
  position: relative;
  padding-left: 1px;
  padding-right: 1px;
}

.charging-container::before {
  content: '';
  position: absolute;
  width: 4px;
  height: 6px;
  /* background: #474747; */
  right: -4px;
  top: 50%;
  margin-top: -3px;
  /* border-radius: 2px; */
  border: 1px solid #474747;
  /* border-left: 1px solid #fff; */
}

.charging-container::after {
  content: '';
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 5px;
  /* right: 10px; */
  background: #474747;
  transition: all 0.3s;
  -webkit-animation: charging 2s steps(1) infinite;
  -moz-animation: charging 2s steps(1) infinite;
  animation: charging 2s steps(1) infinite;
}

.charging-container:hover::after {
  animation-play-state: paused;
}

.battery-blk {
  width: 23px;
}

.battery-percentage {
  height: 6px;
  padding: 0px;
  margin-top: 1px;
  margin-left: 0px;
  margin-right: 0px;
}

.fleetbattery .battery-percentage {
  height: 11px;
}

.fleetbattery .battery-blk {
  width: 34px;
  margin-bottom: 8px;
  margin-top: 7px;
}

.fleetbattery .charging-container {
  height: 15px;
}

.diagbattery .battery-percentage {
  height: 13px;
}

.diagbattery .battery-blk {
  width: 38px;
  display: block;
  margin: auto;
}

.diagbattery .charging-container {
  height: 17px;
  width: 30px;
}

tr.row-selected {
  background-color: #fafafa !important;
}

tr.row-selected td.ant-table-cell.ant-table-column-sort {
  background-color: #fafafa !important;
}

.fullWidth>.videoSnaps {
  width: 100% !important;
}

.ant-upload-list-picture-card-container {
  display: none;
}

.vExp {
  right: 20px !important;
  top: 15px !important;
  position: absolute !important;
  left: inherit !important;
}

.videoDbCon .ant-card-head {
  padding-left: 20px !important;
  padding-right: 0px !important;
  text-align: left;
  display: flex;
}

.notificationEdit {
  position: relative;
}

.notificationEdit .notiLoader {
  position: absolute !important;
  left: 35% !important;
  top: 48% !important;
  margin: 0 !important;
}

.notiLoader img {
  margin: 0 !important;
  padding: 0;
}

.notificationEdit {
  padding: 0px 0 20px 0;
  height: calc(100vh - 19vh);
}

.profCont .notificationEdit .infinite-scroll-component {
  height: calc(100vh - 22vh) !important;
  max-height: calc(100vh - 22vh) !important;
}

.vediocontiner {
  display: block !important;
}

.noVideofile {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fafafa;
  border-radius: 5px;
  z-index: 9;
}

.noVideofile1 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fafafa;
  border-radius: 5px;
  z-index: 0;
  border: 1px solid #f1f1f1;
}

label.form-label {
  font-size: 16px;
  margin: 4px;
  display: block;
}

.editimgIcn {
  bottom: 6px !important;
}

/* @media (min-width: 1440px) and (max-width: 1599px){
  .editImage .editImgs{
    right: 15px;
    bottom: 25px !important;
    transform: inherit;
    top: inherit;
    left: inherit;
  }
}
@media (min-width: 1600px) and (max-width: 1920px){
  .editImage .editImgs{
    right: 15px;
    bottom: 30px !important;
    transform: inherit;
    top: inherit;
    left: inherit;
  }
}
@media (max-width: 1439px){
  .editImage .editImgs{
    bottom: 25px !important;
  }
} */

.max-height-300 {
  height: 310px;
}

.addEmpBlk .proPic .ant-avatar {
  width: 146px;
  height: 146px;
}

.addEmpBlk .editImgs {
  top: unset !important;
  bottom: 0px !important;
  right: 0px !important;
  left: unset !important;
  transform: unset !important;
}

.addEmpBlk .proPic .ant-avatar .ant-avatar-string {
  width: 100% !important;
  height: 100% !important;
}

.warning-msg {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 26px;
  color: #959595;
  opacity: 1;
  font-family: 'Montserrat-Regular', sans-serif;
  font-style: italic;
  font-weight: 400;
}

.mb-10 {
  margin-bottom: 10px;
}

.mediaContainer video::-webkit-media-controls-fullscreen-button {
  display: none;
}

.teamName {
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 14px;
  letter-spacing: 0.12px;
  margin-bottom: 6px;
  /* color: #000; */
}

.teamDescription {
  font-family: 'Montserrat-Regular', sans-serif;
  font-size: 12px;
  letter-spacing: 0.19px;
  white-space: initial;
  color: #333;
  word-wrap: break-word;
  word-spacing: 2px;
}

.addTeamsCon .ant-select-item-option-content {
  white-space: pre-wrap !important;
}

.addEmployeeSelect .ant-select-item-option-content {
  padding: 8px;
}

.addEmployeeSelect .teamDescription {
  display: none;
}

.teamTxt {
  /* min-height: 47px;
  font-size: 16px;
  font-family: 'Montserrat-Medium', sans-serif;
  letter-spacing: 0.15px;
  line-height: 19px; */
  color: #5c5c5c;
}

/* .teamTxt .ant-select-selector {
  min-height: 47px;
} */

.teamTxt .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
  display: none;
}

.load-center {
  transform: translate(-50%, -50%);
  top: 60% !important;
}

/* CSelect */

/* input.cselect {
  position: absolute;
  opacity: 0;
  z-index: -1;
} */

/* Accordion styles */

.flblWrapper {
  /* margin: 100px auto; */
  border-radius: 10px;
  min-width: 170px;
  /* height: 50px; */
  background: #fff;
  box-shadow: 0px 0px 6px #00000029;
  position: relative;
}

.flbBlock {
  width: 100%;
  color: white;
  overflow: hidden;
}

.tab-label,
.tab-label:hover {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: #fff;
  cursor: pointer;
  padding-left: 24px;
  padding-right: 24px;
  color: #000;
  height: 50px;
  /* Icon */
}

.tab-label:hover {
  background: #fff;
}

.tab-label::after {
  content: url('../../assets/images/ddArrow.svg');
  text-align: center;
  transition: all 0.35s;
  position: relative;
  top: 3px;
}

.tab-content {
  position: absolute;
  /* max-height: 0; */
  color: #2c3e50;
  background: white;
  transition: 0.25s;
  width: 100%;
  z-index: 4;
  padding-top: 8px;
  padding-bottom: 8px;

  /* border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc; */
  border-radius: 0px 0px 10px 10px;
  top: 40px;
  box-shadow: 0px 6px 6px #00000029;
}

.tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}

.tab-close:hover {
  background: #1a252f;
}

/* input.cselect:checked + .tab-label {
  background: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
input.cselect:checked + .tab-label::after {
  transform: rotate(180deg);
}
input.cselect:checked ~ .tab-content {
  max-height: 100vh;
  padding: 0px 0px 6px 0px;
  border-radius: 0 0 10px 10px;
} */
.flbSelCont {
  position: relative;
  top: 4px;
}

.flbTopFixed {
  font-family: 'Montserrat-SemiBold', sans-serif;
  position: absolute;
  top: -8px;
  text-transform: uppercase;
  font-size: 8px;
  color: #b6b6b7;
  transition: 0.2s ease all;
}

.flbSelected {
  font-family: 'Montserrat-Regular', sans-serif;
  font-size: 14px;
  color: #000;
  padding: 4px 4px 0px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  display: flex;
}

.tractor-status .flbSelCont {
  flex: 1;
}

.tractor-status .flbSelected {
  max-width: none;
}

.flbContLine {
  width: calc(100% - 48px);
  height: 1px;
  margin: 0 auto;
  background: #f7f7f7;
  position: relative;
  top: -2px;
  display: none;
}

/* input.cselect:checked + .tab-label ~ .tab-content > .flbContLine {
  display: block;
} */

.selectOpen .flbContLine {
  display: block;
}

.flbUL {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-top: 5px;
  overflow-y: auto;
  max-height: 150px;
}

.flbUL li.item {
  font-family: 'Montserrat-Regular', sans-serif;
  font-size: 14px;
  height: 30px;
  padding-top: 5px;
  padding-left: 24px;
  padding-right: 24px;
  border-left: 3px solid transparent;
  cursor: pointer;
  margin-bottom: 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .flbUL li.item:last-child {
  margin-bottom: 0;
} */
.flbUL li.item:hover {
  background: #fafafa;
}

.flbUL li.item.sel {
  border-left: 3px solid #67afb7;
}

.selGray {
  font-family: 'Montserrat-Regular', sans-serif;
  color: #b6b6b7;
  font-style: italic;
}

.all-select .item:first-child {
  font-family: 'Montserrat-Regular', sans-serif;
  color: #b6b6b7;
  font-style: italic;
}

.selNor {
  top: -2px;
}

.selNor.tab-label::after {
  top: -2px;
}

.sel200 {
  width: 200px;
}

.sel180 {
  width: 180px;
}

.selectOpen .tab-label::after {
  transform: rotate(180deg);
  top: 6px;
}

.selectOpen .selNor.tab-label::after {
  top: 2px;
}

.CSelect.dropdownStyle {
  box-shadow: none !important;
  border: none !important;
}

.CSelectTractorStatus {
  min-width: 200px;
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#CSELECT-Type .tab-label::after {
  top: 3px !important;
}

.implementLable {
  left: 10px !important;
  margin-top: 3px !important;
  font-size: 9px !important;
  text-transform: uppercase;
}

@media (max-width: 1500px) {
  .selectImplement .ant-select-selection-item {
    font-size: 0.8em !important;
  }

  .selectImplementContainer .ant-select-selection-placeholder {
    font-size: 0.9em !important;
  }

  .selectImplementContainer .selectImplement .ant-select-selection-item {
    font-size: 1em !important;
  }

  .inputTop {
    font-size: 0.5em;
  }
}

.selectImplementContainer .selectImplement .ant-select-selection-item {
  padding-top: 10px !important;
  font-size: 16px;
  font-family: 'Montserrat-Regular', sans-serif !important;
  color: #000000d9 !important;
}

.selectImplementContainer .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 6vh !important;
  border-radius: 10px;
}

.formStyles .loader {
  height: inherit !important;
}

.profileView .errMsg .ant-select-selector {
  border: 1px solid #f00 !important;
}

/* Farm Observation Container */

.farm-observation-container {
  display: flex;
  flex-direction: column;
}

.fbs-save {
  border-radius: 20px;
  background-color: #60b2b9;
  color: white;
}

.autonomySaveBtn.disabled {
  color: gray !important;
  cursor: not-allowed;
}

.operationParamsModal .ant-modal-content {
  box-shadow: 3px 3px 6px #00000029;
  border: 1px solid #707070;
  border-radius: 15px;
  width: 605px !important;
}

.operationParamsModal .operationRightTxt {
  width: auto !important;
}

.operationParamsHeading {
  font-size: 24px;
  font-family: 'Montserrat-Medium', sans-serif;
  color: #546cb2;
  text-align: center;
  margin-bottom: 24px;
  line-height: 29px;
}

.operationParamsDesp {
  font-size: 14px;
  font-family: 'Montserrat-Regular', sans-serif;
  letter-spacing: 0.22px;
  text-align: center;
  color: #5c5c5c;
  line-height: 18px;
}

.operationParamsContainer {
  padding: 0px 46px 16px 46px;
}

.opsSettings {
  padding: 43px;
  margin-bottom: 24px;
}

.opsSettings .twoValueField {
  max-width: 100% !important;
}

/* .opsSettings .ptoLeft {
  justify-content: left;
} */
.operationParamsContainer .hitchImg {
  border-radius: 5px !important;
  width: 45px !important;
  height: 45px !important;
}

.operationParamsContainer .autodriveSubHead {
  font-size: 12px !important;
}

.operationParamsContainer .ptoWidgetBlk .autodriveSubHead {
  color: #5c5c5c !important;
  width: 45px !important;
}

.operationParamsContainer .mbh24 {
  margin-bottom: 48px !important;
}

.operationParamsBtns {
  justify-content: flex-end;
}

.operationParamsBtns .cancelBtn,
.operationParamsBtns .cancelBtn:hover {
  box-shadow: none !important;
  color: #366376 !important;
  letter-spacing: 0.95px !important;
  line-height: 15px !important;
  margin-right: 57px;
  text-transform: uppercase;
}

.operationParamsBtns .automatedBtn {
  margin: 0 !important;
}

/* Farm Observation Container Ends */
.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}

.img-preview {
  overflow: hidden;
}

.plainBtnSec {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: 20px;
  width: auto !important;
  gap: 15px;
}

.plainBtnSec .editEmpBtn {
  color: #366376;
  cursor: pointer;
}

.plainBtnSec .delBtn {
  color: #de827a;
  cursor: pointer;
}

.delEmpModal .anticon-exclamation-circle {
  margin-top: 5px !important;
}

.delEmpModal .ant-modal-content {
  border-radius: 4px;
}

.delEmpModal .ant-modal-confirm-content,
.delEmpModal .ant-modal-confirm-content p {
  margin-top: 0;
  font-size: 16px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}

.delEmpModal .okBtn {
  margin-right: 0 !important;
}

.ant-modal-confirm.delEmpModal .ant-modal-body {
  padding: 24px !important;
}

.editEmpModal .ant-modal-confirm-body {
  display: flex;
  align-items: flex-start;
}

.editEmpModal .ant-modal-body {
  padding-bottom: 15px !important;
}

.editEmpModal .ant-modal-footer {
  border-top: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 14px !important;
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.editEmpModal .ant-modal-body input:hover,
.editEmpModal .ant-modal-body input:focus {
  border: 1px solid #d9d9d9 !important;
}

.editEmpModal .ant-modal-confirm-content p {
  font-family: 'Montserrat-Medium', sans-serif !important;
}

.editEmpModal .ant-modal-confirm-content span {
  font-family: 'Montserrat-SemiBold', sans-serif !important;
}

.noteText {
  text-align: left;
  padding-top: 35px;
  margin-bottom: 0px;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}

.severity .ant-select-item-option-content {
  grid-gap: unset !important;
  gap: unset !important;
}

.highlight-row {
  background-color: #fdf0f0 !important;
}

.highlight-row:hover td {
  background-color: #fdf0f0 !important;
  /* Change to whatever color you want */
}

.tagGold {
  margin-left: 10px !important;
}

.swVersion {
  word-break: break-word !important;
  white-space: unset !important;
  overflow: unset !important;
}

.video_progress_tooltip {
  padding: 1px 8px;
  position: absolute;
  top: -22px;
  background: rgba(0, 122, 255, 0.4);
  border-radius: 4px;
  color: white;
  font-size: 8px;
  letter-spacing: 1px;
  font-weight: 500;
  height: 12px;
  display: flex;
  align-items: center;
  min-width: 114px;
  z-index: 1;
}

.chunk_progress_tooltip {
  padding: 4px 6px;
  position: absolute;
  top: -22px;
  background: rgba(0, 122, 255, 0.4);
  border-radius: 4px;
  color: white;
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: 600;
  height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  z-index: 2;
  box-shadow: 0px 1px 6px #0000001a;
}

.defaultBtn {
  /* max-width: 120px; */
  height: 32px;
  background-color: #366376;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  padding: 10px;
  border-radius: 6px;
  margin-left: auto;
  cursor: pointer;
  font-family: 'Montserrat-Medium', sans-serif;
  gap: 10px;
}

.defaultBtn:hover {
  background-color: #366376;
}

.errorTxt {
  color: #ff0000 !important;
}

.video_progress__chunks {
  display: flex;
  position: absolute;
  width: 100%;
}

.video_progress__chunk:first-child {
  border-radius: 0px 0px 0px 10px;
}

.video_progress__chunk:last-child {
  border-radius: 0px 10px 0px 0px;
}

.video_progress__chunk.active,
.video_progress__chunk:hover {
  background-color: #386474;
  opacity: 0.5;
  height: 6px;
}

.video_progress__chunk.fillled {
  background-color: #386474;
  opacity: 1;
}

/* Hide Video Controls */

.thubnailVideosFull .video-react-control-bar.video-react-control-bar-auto-hide {
  /* visibility: hidden !important; */
  background: none !important;
}

.thubnailVideosFull .video-react-play-control,
.thubnailVideosFull .video-react-volume-menu-button-horizontal,
.thubnailVideosFull .video-react-current-time,
.thubnailVideosFull .video-react-duration,
.thubnailVideosFull .video-react-time-divider,
.thubnailVideosFull .video-react-progress-control.video-react-control,
.thubnailVideosFull .video-react-fullscreen-control {
  visibility: hidden !important;
}

.hide-controls .video-react-control-bar.video-react-control-bar-auto-hide {
  /* visibility: hidden !important; */
  background: none !important;
}

.hide-controls .video-react-play-control,
.hide-controls .video-react-volume-menu-button-horizontal,
.hide-controls .video-react-current-time,
.hide-controls .video-react-duration,
.hide-controls .video-react-time-divider,
.hide-controls .video-react-progress-control.video-react-control,
.hide-controls .video-react-fullscreen-control {
  visibility: hidden !important;
}

.video-react-button.video-react-big-play-button.video-react-big-play-button-left,
.video-react.video-react-big-play-button {
  visibility: hidden !important;
}

.iconSize .video-react .video-react-control:before {
  font-size: 2.4em;
  line-height: 1.3;
}

.iconSize .video-react-current-time-display,
.iconSize .video-react-duration-display {
  font-size: 14px;
}


