/* .chartsTabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0px !important;
} */
.chartsTabs .ant-tabs-nav {
  height: auto !important;
}

.attContainer .chartsTabs .ant-tabs-tab-btn {
  font-size: 13px !important;
}

.chartTabsContainer {
  /* padding: 20px; */
  /* display: block; */
  /* width: 94%; */
  /* margin: 0 auto;
  display: flex; */
}

/* .ant-tabs.ant-tabs-top.chartsTabs {
    height: calc(100vh - 20vh);

} */

.chartGraph {
  background-color: #fff;
  border-radius: 15px;
  border: 1px solid #e7e7e7;
  padding: 15px;
}
.w100 {
  width: calc(100% - 0px);
  margin-right: 0px;
  height: auto;
  /* margin-bottom: 20px; */
  /* overflow-y: auto; */
}
.w68 {
  width: calc(68% - 15px);
  margin-right: 15px;
  height: 70vh;
  overflow-y: auto;
}
.w32 {
  width: 32%;
  height: 70vh;
  overflow-y: auto;
}
.w50 {
  width: calc(50% - 15px);
  margin-right: 15px;
  height: 70vh;
  overflow-y: auto;
}
.w51 {
  width: calc(50% - 0px);
  height: 70vh;
  overflow-y: auto;
}
.w52 {
  width: calc(50% - 0px);
  overflow-y: auto;
}
.dflex {
  display: flex;
}
.jcsb {
  justify-content: space-between;
}
.mr0 {
  margin-right: 0px !important;
}

.hourWages,
.hourWages:focus,
.hourWages:hover,
.hourWages:focus-visible {
  background: transparent;
  border: none;
  text-align: right;
  width: 70px;
  box-shadow: inherit;
  outline: none;
}
.underline {
  display: none;
}

.text-3xl {
  font-size: 16px;
  letter-spacing: 0.5px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}
#operator-wage-container .py-3 {
  font-size: 13px;
}
.text-xl {
  font-size: 12px;
}
/* .text-3xl {
  margin-bottom: 10px;
} */
.intraction {
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
}
.intraTxt {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  margin-bottom: 5px;
  display: inline-block;
}
.costSaveBlock {
  background-color: #f8f8f8;
  padding: 25px 20px 15px 20px;
  border-radius: 15px 15px 0 0;
}
#operator-wage-container {
  padding: 0px 20px 0px 20px;
}
#operator-wage-container .table-fix th {
  border-bottom: 1px solid #efefee !important;
}
#operator-wage-container .table-fix th {
  background-color: transparent !important;
}
.assumeTxt {
  font-size: 12px;
  padding: 5px 20px;
  margin-bottom: 10px;
}
.mb5 {
  margin-bottom: 5px;
}
.recalc {
  padding-left: 15px;
  font-size: 11px;
}
.padChart {
  padding: 20px 20px 15px 20px;
}
.flexNone {
  display: inherit;
}
.tractorUage {
  margin-bottom: 20px;
  overflow-y: scroll;
  height: 100vh;
}

.elTabs .chartsTabs .ant-tabs-content {
  height: calc(100vh - 28vh) !important;
  margin-bottom: 22px;
}
.noFlex {
  display: block !important;
}

.hvh_Graph {
  height: calc(100vh - 30vh);
}

.ddDuration {
  font-size: 18px;
  position: absolute;
  right: 342px;
  top: 15px;
  z-index: 99999;
}
.totalEUS {
  position: absolute;
  right: 20px;
  top: 15px;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  min-width: 300px;
  min-height: 80px;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
}
.totalEUS_left {
  width: 50%;
  border-right: 1px solid #e7e7e7;
  padding: 10px;
}
.totalEUS_right {
  width: 50%;
  padding: 10px;
}
.euHead {
  font-size: 12px;
  color: #919191;
  text-align: center;
  margin-bottom: 8px;
}
.euValues {
  text-align: center;
}
.euValuesTxt {
  font-size: 20px;
  color: #000;
  margin-right: 5px;
}
.euValuesMsr {
  font-size: 12px;
  color: #919191;
}
.aHead {
  font-size: 14px !important;
  font-family: 'Montserrat-Medium', sans-serif !important;
  color: #919191 !important;
  margin-bottom: 0px;
}

.aVal {
  font-size: 20px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #000;
}
.aValTxt {
  font-size: 14px;
  font-family: 'Montserrat-Medium', sans-serif;
  color: #000;
}
.aHead2 {
  font-size: 16px !important;
  font-family: 'Montserrat-Medium', sans-serif !important;
  color: #000 !important;
  margin-bottom: 5px !important;
}

.opCostSec {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid #e7e7e7;
}
.bbNone {
  border-bottom: none !important;
}
.mb15 {
  margin-bottom: 15px;
}
.mb10 {
  margin-bottom: 10px;
}
.anarightSec h6 {
  margin-bottom: 8px;
}

.ml35 {
  margin-left: 35px !important;
}

.tractorUage .teamarrow {
  /* width: 30px !important; */
  height: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px !important;
  margin-top: 10px !important;
}
.tractor-title {
  position: absolute;
  right: 0;
  font-size: 24px;
  padding: 0px 10px;
  font-weight: 600;
}

.ddDurationenergy {
  font-size: 18px;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 99999;
}
