.dashboardContainer {
    padding: 24px 27px;
}

.historyBackHead {
    min-height: 41px;
    display: flex;
    justify-content: space-between;
}
.headPlusBack {
    display: flex;
    gap: 11px;
    align-items: center;
}
.backButtonBig {
    width: 41px !important;
    height: 41px !important;
    position: relative;
    left: -5px;
    top: 2px ;
    cursor: pointer;
}
.headPlusTxt {
    font-size: 20px;
    font-family: 'Montserrat-SemiBold';
    line-height: 25px;
}