.cardsContainer {
    display: flex;
    height: 100vh;
    background-color: #f7f7f7;
    flex-direction: column;
}

.cardsHeader {
    height: 59px;
    border-bottom: 1px solid #e7e7e7;
    box-shadow: 0px 0px 3px #00000009;
    background-color: #fff;
    width: 100%;
    position: relative;
}

.cardsContentArea {
    padding: 27px;
    height: calc(100vh - 59px);
    overflow-y: auto;
}

.cardsHeader:before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 1px solid #f0f0f0;
    content: '';
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    bottom: 0;
}

.cardsTitle {
    font-size: 20px;
    font-family: 'Montserrat-SemiBold', sans-serif;
    color: #333;
    line-height: 24px;
}

.cardsBlockParent {
    display: flex;
    /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 16px; */
    gap: 16px;
    flex-wrap: wrap;
}

.cardsBlock {
    background-color: #fff;
    min-height: 260px;
    border-radius: 12px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    padding: 30px;
    cursor: pointer;
    border: 1px solid transparent;
    width: calc(25% - 12px);
}

.backtoCardsBtn {
    position: relative;
    top: -2px;
    left: -2px;
}

/* .pl60 {
    padding-left:60px;
  } */
.cardsBlock:hover {
    border: 1px solid #366376;
    box-sizing: border-box;
}

.cardsInfoSec {
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.cardsmtb {
    /* margin-top: 30px; */
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.cardsmtb img {
    display: flex;
    justify-content: center;
    background-color: #f6feff;
    border-radius: 50%;
    height: 60px;
    border: 1px solid #b7e1f4;
    padding: 8px;
    width: 60px;
}

.cardsHeadTxt {
    font-size: 18px;
    font-family: 'Montserrat-Bold', sans-serif;
    color: #366376;
    line-height: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.cardsDesc {
    font-size: 15px;
    font-family: 'Montserrat-Regular', sans-serif;
    color: #000;
    line-height: 22px;
    word-wrap: break-word;
    /* height: 34px; */
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}