// .dashboardContainer {
//     padding: 24px 27px;
// }

.historyBackHead {
    min-height: 41px;
    display: flex;
    justify-content: space-between;
}

.headPlusBack {
    display: flex;
    gap: 11px;
    align-items: center;
}

.headPlusTxt {
    font-size: 20px;
    font-family: 'Montserrat-SemiBold';
    line-height: 25px;
}

.backButtonBig {
    width: 41px !important;
    height: 41px !important;
    position: relative;
    left: -5px;
    top: 2px;
}

.fpDashboard_wrapper {
    height: calc(100vh - 109px) !important;
}

.ms_timeStamp {
    font-size: 14px;
    font-family: 'Montserrat-Medium';
    line-height: 17px;
}

.headHomeTxt {
    font-size: 24px;
    font-family: 'Montserrat-SemiBold';
    line-height: 29px;
}

.ms_dashboardHead {
    min-height: 30px;
    display: flex;
    justify-content: space-between;
}

.ms_dashboardWidgetCard {
    background-color: #fff;
    min-height: 101px;
    border-radius: 8px;
    border: 1px solid #EEEEEE;
    padding: 12px;
    display: flex;
}

.ms_dashboardCard {
    background-color: #fff;
    // min-height: 101px;
    border-radius: 8px;
    border: 1px solid #EEEEEE;
    padding: 12px;
    height: 100%;
}

.ms_CardRow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.ms_CardColumn {
    display: flex;
    gap: 9px;
    align-items: center;

}

.ms_CardColumn2 {
    display: flex;
    gap: 9px;
    align-items: flex-end;
    justify-content: space-between;
}

.ms_CardColumn3 {
    display: flex;
    gap: 9px;
    align-items: center;
    justify-content: space-between;
}

.alignItemStart {
    align-items: flex-start !important;
}

.ms_CardTitle {
    font-size: 14px;
    font-family: 'Montserrat-Medium';
    line-height: 17px;
    color: #99999C;
}

.ms_CardCount {
    font-size: 36px;
    font-family: 'Montserrat-SemiBold';
    line-height: 38px;
    color: #333333;
}

.ms_CardIndex {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 10px;
    font-family: 'Montserrat-Regular';
    color: #B5B5B6;
}

.ms_CardIncTxt {
    font-size: 10px;
    font-family: 'Montserrat-SemiBold';
    line-height: 12px;
}

.incGreen {
    color: #379526
}

.decRed {
    color: #D43D38
}

.dashboardContainer .ant-row {
    row-gap: inherit !important;
}

.dashboardContainer .ant-row>div {
    margin-top: 10px;
    margin-bottom: 10px;
}

.flexDirection {
    flex-direction: column;
}

.ms_customerUsage {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ms_blockHead {
    font-size: 14px;
    font-family: 'Montserrat-Medium';
    line-height: 17px;
    color: #333333;
}

.imgMaxWidth {
    max-width: 100%;
    width: 100%;
}

.ms_status {
    display: flex;
    gap: 6px;
    align-items: center;
    background-color: #fff;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    padding: 4px 10px;
    min-height: 24px;
    color: #B5B5B6;
    cursor: pointer;
    font-size: 10px;
    font-family: 'Montserrat-Medium';
}

.ms_ticket_card {
    background-color: #F7F7F7;
    border-radius: 10px;
    padding: 8px 10px;
    min-height: 92px;
    display: flex;
}

.ms_status_chip {
    border-radius: 25px;
    padding: 3px 10px;
    font-size: 8px;
    font-family: 'Montserrat-Regular';
    min-height: 16px;
}

.ms_ticket_no {
    display: flex;
    gap: 4px;
    align-items: center;
}

.ms_round {
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    text-transform: uppercase;
    font-size: 8px;
    font-family: 'Montserrat-SemiBold';
    color: #fff;
}

.ms_circle_green {
    background-color: #366376;
}

.ms_circle_orange {
    background-color: #F5DFC3;
}

.ms_ticket_dtls {
    font-size: 12px;
    font-family: 'Montserrat-Medium';
    color: #000;
}

.ticket_open {
    background-color: #F4E8E6;
    color: #DE827A;
}

.ticket_inprogress {
    background-color: #F6EAD8;
    color: #EB9223;
}

.ms_ticket_desc {
    font-size: 10px;
    font-family: 'Montserrat-Regular';
    color: #1F1F1F;
    margin-bottom: 2px;
}

.ms_ticket_loc_date {
    font-size: 8px;
    font-family: 'Montserrat-Regular';
    color: #5C5C5C;
    display: flex;
    gap: 4px;
    align-items: center;
}

.ms_dot {
    background-color: #B5B5B6;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    display: flex;
}

.ms_ticket_flags {
    display: flex;
    gap: 6px;
    align-items: center;
}


/* ************* org prifle sytels goes here *********************** */


.ms_top_bg_gd {
    background-image: url(../../assets//images/ms_bg_gradient.svg);
    background-size: cover;
    background-color: #fff;
    // min-height: 30vh;
    width: 100%;
    border-radius: 10px 10px 0 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    height: 40vh;
}

.ms_org_card {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #eee;
    min-height: calc(100vh - 48px);
    position: relative;
    padding: 18px 24px 24px 24px;
    height: 100%;
}

.ms_org_card2 {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #eee;
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.justify-content-sb {
    justify-content: space-between !important;
}

.ms_button_sec {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.ms_btn {
    background-color: transparent;
    border: 1px solid #366376;
    border-radius: 10px;
    padding: 10px;
    min-height: 36px;
    color: #366376;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    font-family: 'Montserrat-SemiBold';
}


.ms_btn_sel,
.ms_btn:hover,
.ms_btn:focus {
    background-color: #366376;
    color: #fff;
}


.openMapIcon {
    background-image: url(../../assets/images/openmap_green.svg);
    width: 16px;
    height: 16px;
}

.editOrgIcon {
    background-image: url(../../assets/images/edit_org_green.svg);
    width: 16px;
    height: 16px;
}

.ms_btn:hover,
.ms_btn_sel {
    .openMapIcon {
        background-image: url(../../assets/images/openmap_white.svg);
        width: 16px;
        height: 16px;
    }

    .editOrgIcon {
        background-image: url(../../assets/images/edit_org_white.svg);
        width: 16px;
        height: 16px;
    }
}

.ms_right_sec {
    display: grid;
    height: 100%;
    row-gap: 24px;
    grid-template-rows: 186px 1fr 1fr;
}

.ms_card_head {
    display: flex;
    justify-content: space-between;
}

.ms_card_txt {
    font-size: 14px;
    font-family: 'Montserrat-SemiBold';
    color: #5c5c5c;
}

.ms_card_title {
    display: flex;
    gap: 9px;
    align-items: center;
}

.ms_CardCount {
    font-size: 36px;
    font-family: 'Montserrat-SemiBold';
    line-height: 38px;
    color: #333333;
}

.ms_card_login_dtl {
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
    border-top: 1px solid #f7f7f7;
}

.ms_login_admin {
    padding: 4px;
    min-width: 75px;
    min-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E1EFF1;
    border-radius: 6px;
    color: #67AFB7;
    font-size: 14px;
    font-family: 'Montserrat-Medium';

}

.ms_username_email {
    display: flex;
    flex-direction: column;
}

.ms_username {
    font-size: 16px;
    font-family: 'Montserrat-SemiBold';
    color: #99999C;
}

.ms_email {
    font-size: 14px;
    font-family: 'Montserrat-Regular';
    color: #99999C;
}

.alignItemCenter {
    align-items: center !important;
}

.mtb0 .ant-row>div {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}



@media (max-width: 991px) {
    .dashboardContainer .ant-row>div {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .ms_right_sec {
        grid-template-rows: 186px 240px 240px !important;
    }
}


.bdrBtm {
    border-bottom: 1px solid #F7F7F7;
    padding-bottom: 12px;
    margin-bottom: 12px;
}

.ms_implement_dtl {
    display: flex;
    gap: 8px;
    align-items: center;
}

.square {
    border-radius: 2px;
    width: 12px;
    height: 12px;
}

.sq_orange {
    background-color: #EC9322;
}

.sq_red {
    background-color: #DE827A;
}

.sq_sgreen {
    background-color: #67AFB7;
}

.sq_green {
    background-color: #A9BF51;
}

.sq_gray {
    background-color: #CFCFCF;
}

.ms_imp_count {
    min-width: 32px;
    font-size: 20px;
    font-family: 'Montserrat-SemiBold';
    color: #474747;
    text-align: center;
}

.ms_imp_category {
    font-size: 14px;
    font-family: 'Montserrat-Medium';
    color: #99999C;

}

.flexDirection {
    flex-direction: column;
}

.dFlex {
    display: flex;
}

.w50Per {
    width: 50%;
}

.justify-start {
    justify-content: flex-start;
}

/* site csss starts here */

.site-header {
    grid-area: header;
}

.site-summary {
    grid-area: summary;
}

.site-basestion {
    grid-area: basestation;
}

.site-tractors {
    grid-area: tractor;
}

.site-actions {
    grid-area: footer;
}

.site-container {
    display: grid;
    grid-template-areas:
        'header header basestation'
        'header header tractor'
        'summary summary tractor'
        'footer footer footer'
    ;
    gap: 20px;
    padding: 16px;
    width: 372px;
    border: 1px solid #EEEEEE;
    border-radius: 12px;
}

.site-container_card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    width: 372px;
    border: 1px solid #EEEEEE;
    border-radius: 12px;
    min-height: 247px;
}

.tractor-list {
    display: grid;
    grid-template-columns: auto auto;
}

.space-between-fw {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 16px;
}

.btnStyles {
    // width: 162px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: 'Montserrat-SemiBold';
    height: 36px;
    padding: 10px;
    border-radius: 8px;
    border-color: #366376;
    color: #366376;
    flex: 1;
    justify-content: center;
    cursor: pointer;
}

.default-btn {
    @extend .btnStyles;

    &:hover {
        @extend.btnStyles;
    }

    &:focus {
        @extend.btnStyles;
    }
}

.primary-btn {
    @extend .btnStyles;
    background-color: #366376;
    color: white;

    &:hover {
        @extend.btnStyles;
        background-color: #366376;
        color: white;
    }

    &:focus {
        @extend.btnStyles;
        background-color: #366376;
        color: white;
    }
}

.splPad50 {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #D9D9D9
}

.mb48 {
    margin-bottom: 48px;
}

.mb55 {
    margin-bottom: 55px;
}

.ms_org_section {
    position: relative;
    z-index: 1;
}

.ms_org_head {
    display: flex;
    align-items: flex-end;
    gap: 20px;
}

.ms_org_logo {
    width: 104px;
    height: 104px;
    border: 2px solid #fff;
    border-radius: 6px;
    background-color: #DDDDDD;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ms_org_title {
    font-size: 24px;
    font-family: 'Montserrat-SemiBold';
    color: #5C5C5C;
    margin-bottom: 18px;
}

.profileImage {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.ms_org_dtls {
    display: flex;
    gap: 40px;
}

.ms_org_dtl_col {
    display: flex;
    gap: 6px;
    flex-direction: column;
}

.ms_org_type {
    font-size: 14px;
    font-family: 'Montserrat-SemiBold';
    color: #5C5C5C;
}

.ms_org_value {
    font-size: 12px;
    font-family: 'Montserrat-Regular';
    color: #5C5C5C;
}

.ms_site-row_sec {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    gap: 20px;
}

.ms_lane_count {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.ms_count_no {
    font-size: 14px;
    font-family: 'Montserrat-SemiBold';
    color: #5C5C5C;
    line-height: 17px;
}

.ms_count_lbl {
    font-size: 12px;
    font-family: 'Montserrat-Regular';
    color: #5C5C5C;
    line-height: 15px;
}

.ms_site_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

}

.ms_site_header {
    font-size: 14px;
    font-family: 'Montserrat-Medium';
    color: #5c5c5c;
}

.ms_site_desc {
    font-size: 12px;
    font-family: 'Montserrat-Regular';
    color: #99999C;
}

.ms_site_head_txt {
    font-size: 12px;
    font-family: 'Montserrat-Regular';
    color: #99999C;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.ms_site-row {
    display: flex;
    flex: 1;
}

.ms_site_head_block {
    display: flex;
    gap: 12px;
    flex-direction: column;
}

.ms_site-row2 {
    display: flex;
    justify-content: space-between;
}

.ms_site_head_txt {
    font-size: 20px;
    font-family: 'Montserrat-SemiBold';
    color: #5c5c5c;
}

.ms_site_address_txt {
    font-size: 12px;
    line-height: 15px;
    font-family: 'Montserrat-Regular';
    color: #5c5c5c;
}

.ms_site_col {
    display: flex;
    gap: 16px;
}

.ms_site_col1 {
    width: 193px;
    padding-right: 12px;
    border-right: 1px solid #D9D9D9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.ms_site_col2 {
    width: 134px;
}

.ms_basestation_block {
    margin-bottom: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    flex-direction: column;
    gap: 9px;
}

.ms_tractors_block {
    margin-bottom: 12px;
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    gap: 9px;
}

.ms_bs_row {
    display: flex;
    margin-bottom: 5px;
    justify-content: space-between;
}

.ms_tractor_block {
    display: flex;
    gap: 8px;
    padding-left: 12px;
    overflow-y: auto;
    height: 55px;
    padding-right: 5px;
    margin-right: -5px;
}

.ms_tractor_block_scroll {
    display: flex;
    gap: 8px;
    padding-left: 12px;
    flex-wrap: wrap;
    // flex-direction: column;
    gap: 9px;
    overflow-y: auto;
    height: 55px;
    padding-right: 5px;
    margin-right: -5px;

}

.ms_tractor_row {
    display: flex;
    margin-bottom: 5px;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
}

.ms_tractor_name {
    font-size: 12px;
    line-height: 15px;
    font-family: 'Montserrat-Regular';
    color: #5c5c5c;
    max-width: 112px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.site-container_card_section {
    display: flex;
    gap: 20px;
    overflow-x: auto;
    // margin-bottom: 10px;
    padding-bottom: 10px;

}

/* site css starts here */

/* edit proflie css start here */
.edit-profile-container {
    display: grid;
    grid-gap: 48px;
    padding: 72px 0px 36px 0px;
    position: relative;
    z-index: 1;
}

.edit-profile-form {
    display: grid;
    grid-gap: 36px;
    width: 50%;
    justify-self: center;
}

.edit-profile-header {
    display: grid;
    grid-template-columns: 100px auto;
    grid-gap: 36px;
    width: 70%;
    justify-self: center;
    align-items: center;
}

.upload-image-block {
    background-color: #DDDDDD;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    position: relative;
    border-radius: 8px;
}

.addPhotoBtn {
    position: absolute;
    background-color: #0000001A;
    width: 100%;
    bottom: 0;
    font-size: 12px;
    text-align: center;
    padding: 8px 0px;
    color: white;
}

.camera-icon {
    align-self: center;
    justify-content: center;
    font-size: 24px;
    color: white;
    padding-bottom: 12px;
}

.org-title {
    color: #DDDDDD !important
}

/* edit proflie css ends here */


/******************* site Farm styles ******************* */

.create_site_popup.ant-modal {
    padding-bottom: 0px !important;
    top: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;

}

.create_site_popup .ant-modal-content {
    border-radius: 8px;
    width: 892px;
}

.create_site_popup .ant-modal-body {
    padding: 32px;
}

.create_site_popup_container {
    display: flex;
    gap: 40px;
    flex-direction: column;
}

.create_site_popup_head {
    font-size: 20px;
    font-family: 'Montserrat-SemiBold';
    color: #5c5c5c;
}

.flex-space-between-block {
    height: -webkit-fill-available;
    justify-content: space-between;

}

.siteLeftCol {
    padding-left: 40px !important;
}

.siteRightCol {
    padding-right: 40px !important;
    border-right: 1px solid #DDDDDD;
}

.create_site_popup_buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
}

.create_site_popup_buttons .default-btn,
.create_site_popup_buttons .default-btn:hover,
.create_site_popup_buttons .default-btn:focus,
.create_site_popup_buttons .primary-btn,
.create_site_popup_buttons .primary-btn:hover,
.create_site_popup_buttons .primary-btn:focus {
    flex: inherit;
    min-width: 100px;
}

/******************* site Farm styles ******************* */


.inCompleteSetUp {
    display: flex;
    align-items: center;
    background-color: #F7F7F7;
    /* Light red */
    color: #DE827A;
    /* Dark red */
    border-radius: 6px;
    font-family: Montserrat;
    padding: 6px 12px;
    margin-bottom: 12px;
    min-height: 28px;
    display: flex;
    gap: 10px;
    align-items: center;

}

.inCompleteSetUpIcon {
    // font-size: 18px;
    // margin-left: 12px;
}

.inCompleteSetUpText {
    font-size: 12px;
    // margin-left: 8px;
    font-weight: 500;
}

.CropEditPosition .editImgs {
    right: 5px !important;
    bottom: 5px !important;
}

.h36 {
    height: 36px;
}

.ms_org_logo {
    position: relative;
}

.ms_org_logo .editImgs {
    position: absolute;
    bottom: 0 !important;
    right: 0 !important;
    left: inherit !important;
    top: inherit !important;
    transform: inherit !important;
}

.w122 {
    max-width: 122px;
}

.w250 {
    width: 250px;
}

.msEditButtons {
    display: flex;
    gap: 24px;
}

.msArrowPointer {
    cursor: pointer;
}

.nbdr {
    border: none !important;
    box-shadow: none;
}


// .create_site_popup_wrapper .widget_card .float-label div{
//     width: inherit !important;
// }
.create_site_popup_wrapper .float-label.msSelect~div .ant-select-selector {
    // width: 100% !important;
}

.create_site_popup_wrapper .widget_card span.ant-select-selection-item {
    // max-width: 150px !important;
    // width: inherit !important;
}

.create_site_popup_wrapper .widget_card .float-label.msSelect .treeSelect {
    // min-width: 100% !important;
}

.create_site_popup_wrapper .widget_card1 .ant-select-selector .ant-select-selection-item {
    min-height: 24px !important;
    height: 24px !important;
    border-radius: 4px !important;
    background: none;
    border: none;
    margin-right: 0px !important;
    padding: 0px 3px;
    margin-top: 3px !important;
}



.create_site_popup_wrapper .ant-select-multiple .ant-select-selection-item-content {
        margin-right: 0px !important;
}
// .float-label.msSelect .ant-select-selection-overflow {
//     overflow: inherit;
// }

.siteRightCol .ant-space.ant-space-horizontal.ant-space-align-center {
    display: flex;
}

.siteRightCol .ant-space.ant-space-horizontal.ant-space-align-center .ant-space-item {
    flex: 1;
}

.siteRightCol .float-label.undefined .ant-select {
    width: 100% !important;
}

.create_site_popup_container .widget_card1 .msSelect .ant-select-selection-overflow {
    width: 305px !important;
    overflow-x: auto;
    display: inline-flex;
    gap: 2px;
    margin-right: 20px;
}

.create_site_popup_container .widget_card1 .msSelect .ant-select-selection-overflow-item {
    width: unset !important;
}

.create_site_popup_container .widget_card1 .msSelect .ant-select-selection-overflow-item:nth-child(5) span.ant-select-selection-item {
    width: inherit !important;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .ms_email {
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

@media (min-width: 1200px) and (max-width: 1365px) {
    .ms_email {
        max-width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

@media (min-width: 1366px) and (max-width: 1919px) {
    .ms_email {
        max-width: 320px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

img.camIcon {
    max-width: 100%;
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.CropEditPosition {
    background: #c5c5c596;
    height: 28px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0 0 4px 4px;
}


.ms_add_photo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    line-height: 7px;
    min-height: 28px;
}

.ms_plus {
    font-size: 10px;
}

.ms_add_photoTxt {
    font-size: 8px;
    font-family: 'Montserrat-SemiBold';
    color: #fff;
}

.ms_org_logo img {
    border-radius: 4px;
    width: 100%;
}

.edit-profile-container .float-label input.ant-input.ant-input-disabled {
    color: #CFCFCF !important;
    background-color: #F7F7F780 !important;
}

.ms_mvalues {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ms_mtractor,
.ms_msites {
    font-size: 12px;
    font-family: 'Montserrat-Regular';
    color: #333;
}

.ms_msites {
    padding-right: 10px;
    padding-left: 10px;
}

.ms_mtractor {
    max-width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.widget_card1 .float-label {
    position: relative;
    /* margin-right: 10px; */
}

.widget_card1 .label {
    font-size: 12px;
    font-weight: normal;
    font-family: 'Montserrat-Regular';
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 10px;
    transition: 0.2s ease all;
    color: #333333 !important;
}

.widget_card1 .label-float {
    top: -8px;
    /* font-size: 10px; */
    background: #fff;
    padding: 0px 5px 0px 5px;
    left: 8px;
    font-size: 12px !important;
    font-family: 'Montserrat-Regular';
    line-height: 14px !important;
    color: #333333;
}

.widget_card1 .float-label div {
    font-size: 10px !important;
    font-family: 'Montserrat-Regular';
    color: #99999c;
}

.widget_card1 .float-label input,
.widget_card1 .float-label input:hover,
.widget_card1 .float-label input:focus {
    min-height: 39px !important;
    height: 39px !important;
    box-sizing: border-box;
    margin: 0;
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 8px 12px;
    color: #333333 !important;
    background: #ffffff;
    border: 1px solid #cfcfcf !important;
    border-radius: 4px !important;
    transition: all 0.3s;
    font-size: 14px !important;
    font-family: 'Montserrat-Medium';
    line-height: 17px !important;
}

.widget_card1 .ant-select-selector .ant-select-selection-item {
    // font-size: 14px !important;
    min-height: 30px !important;
    height: 30px !important;
    margin-bottom: 1px !important;
}

.widget_card1 .float-label .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;
}

.widget_card1 .float-label .ant-select-selection-item,
.widget_card1 .float-label input div,
.rightWidgetView.widget_card1 .ant-select-selector .ant-select-selection-item {
     font-size: 14px !important;
    /* line-height: 17px !important; */
     font-family: 'Montserrat-Medium';
     color: #333333;
}

.widget_card1 .ant-select .ant-select-selector {
    background: #ffffff !important;
    border: 1px solid #cfcfcf !important;
    border-radius: 4px !important;
    min-height: 39px !important;
    height: 39px !important;
}

.widget_card1 .ant-select input,
.widget_card1 .ant-select input:hover,
.widget_card1 .ant-select input:focus {
    border: 0 !important;
}

.widget_card1 .ant-select-selection-placeholder {
    display: none;
}

.widget_card1 .dimPos {
    top: 50% !important;
    transform: translateY(-50%);
    bottom: unset !important;
}

.ms_mvalues .ms_mtractor {
    max-width: 100%;
}

.StateCityCode {
    display: grid;
    grid-template-columns: 130px 88px 110px;
    gap: 16px;
}

.UpdateYourFleet {
    display: flex;
    /* Enable Flexbox */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    height: 100%;
    /* Set a height for the div */
    color: #99999C;
}

.UpdateYourFleetDisable {
    color: #B5B5B6 !important;
}

.multiSiteHoverInfo {
    display: flex;
    align-items: center;
    height: 45px;
    font-size: 12px;
    color: #de827a;
    border-radius: 6px;
}

.multiSiteHoverInfo .float-label {
    width: 100%;
}

.remove_right_border {
    border-right: none;
}

.remove_bottom_border {
    border-bottom: none;
}

//   added this code hide and show state name and iso code in state dropwdown

.msSelect .ms_msites {
    display: none;
}

.msSelect .ant-select-open .ms_msites {
    display: block;
}

.msSelect .ant-select-selection-item .ms_msites {
    display: none;
}

//   added this code hide and show state name and iso code in state dropwdown
//   added this code hide and show comma in tarctor dropwdown
.commaCode {
    display: none;
}

.msSelect .ant-select-selection-item .commaCode {
    display: block;
    padding-left: 2px;
    color: #333;
    font-size: 14px;
}
.haveSingleRecord .commaCode{
    display: none !important;
}

.ms_mtractor {
    display: flex;
}
//   added this code hide and show comma in tarctor dropwdown

  .create_site_popup_wrapper span.ant-select-selection-item-remove {
    display: none;
  }

  .create_site_popup_wrapper .widget_card1 .msTractorOS.float-label div {
    color: #333 !important;
    font-size: 14px !important;
    font-family: 'Montserrat-Medium';
  }
.ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
    display: none;
}


.create_site_popup_wrapper .ant-select .ant-select-arrow {
    content: url(../../assets/images/aDown.svg);
    width: 14px;
    height: 14px;
    margin-top: -7px;
}
.create_site_popup_wrapper .ant-select-open .ant-select-arrow {
    content: url(../../assets/images/aUP.svg) !important;
    width: 14px;
    height: 14px;
    margin-top: -7px;
}

.arowSpl::after {
    position: absolute;
    content: url(../../assets/images/aDown.svg);
    width: 14px;
    height: 14px;
    right: 11px;
    z-index: 1;
    top: 10px;
}

.arowSpl:has(.ant-select-open)::after {
    transform: rotate(180deg);
    top: 14px;
}

.create_site_popup_wrapper .ant-select-clear {
    display: none;
}

.w1 .ant-tree-select {
    width: 100%;
}