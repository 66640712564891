/* Digital Pacakge modal Styles */

.packageModalFontStyles {
    font-family: Montserrat;
    line-height: 100%;
    letter-spacing: 0%;
}

.dgps-modal-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dgps-modal {
    position: absolute;
    width: 618px;
    background-color: #fff;
    border-radius: 12px;
    padding: 28px 0px;

}

.dgps-modal-content {
    padding: 0px 40px;
}

.dgps-modal-header {
    display: flex;
    gap: 10px;
}

.dgps-modal-title {
    font-weight: 600;
    font-size: 16px;
    @extend .packageModalFontStyles;
    color: #333333;
}

.dgps-modal-description {
    @extend .packageModalFontStyles;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #99999C;
}


.dgps-modal-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.dgps-modal-body {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

div.text {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #474747
}

.dgps-section-1 {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    gap: 24px;
    padding: 24px;
    background-color: #F7F7F7;

    .dgps-map-section {
        width: 332px;
        height: 144px;
        border-radius: 10px;
        justify-content: space-between;
        padding: 8px;
        background-color: #FFFFFF;
        display: flex;
    }

    .dgps-user-section {
        width: 158px;
        height: 144px;
        border-radius: 12px;
        padding: 8px 6px;
        gap: 16px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;

        .user-block {
            display: flex;
            gap: 8px;

            .user-img-block {
                background-color: #8595CA33;
                width: 31px;
                height: 31px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
            }

            .user-info {
                display: flex;
                gap: 4px;
                padding-top: 4px;
                flex-direction: column;

                .user-name {
                    @extend .packageModalFontStyles;
                    font-weight: 500;
                    font-size: 10px;
                }

                .user-tag {
                    width: 75;
                    height: 14;
                    border-radius: 4px;
                    padding: 2px 4px;
                    background-color: #DEEAF8;
                    color: #007AFF;
                    font-weight: 500;
                    font-size: 8px;
                    @extend .packageModalFontStyles;
                }
            }
        }

        .access-block {
            padding-left: 10px;
            display: flex;
            flex-direction: column;
            gap: 4px;

            .access-block-item {
                display: flex;
                gap: 8px;
                align-items: center;

                .access-check {
                    display: flex;
                }

                .access-label {
                    @extend .packageModalFontStyles;
                    font-weight: 400;
                    font-size: 8px;
                    color: #333333
                }
            }
        }
    }

    .dgps-ticket-section {
        display: flex;
        gap: 10px;
    }

    .dgps-ticket {
        width: 158px;
        height: 38px;
        border-radius: 6px;
        gap: 10px;
        padding: 4px;
        border-bottom-width: 1px;
        background-color: #FFFFFF;
    }
}

.plans-container {
    display: flex;
    gap: 16px;

    .plan-card {
        width: 168px;
        height: 92px;
        border-radius: 10px;
        justify-content: space-between;
        padding: 12px;
        background: #F7F7F799;
        display: flex;
        gap: 10px;

        .plan-section {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        // flex-direction: column;
        .plan-title {
            @extend .packageModalFontStyles;
            font-weight: 600;
            font-size: 14px;
            color: #4078C0;
        }

        .plan-desc {
            @extend .packageModalFontStyles;
            font-weight: 500;
            font-size: 12px;
            color: #99999C;
        }
    }
}

.map-user-container {
    display: grid;
    grid-template-columns: 332px 158px;
    gap: 16px;
}

.dgps-section-3 {
    display: flex;
    height: 36;
    gap: 10px;
    align-items: center;

    .contact-text {
        @extend .packageModalFontStyles;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: #474747;
    }

    .learnMoreBtn {
        width: 90;
        height: 36;
        border-radius: 8px;
        gap: 6px;
        border-width: 1px;
        padding: 10px;
        color: #366376;
        border: 1px solid #366376
    }
}

.ticketFontStyle {
    @extend .packageModalFontStyles;
    font-size: 8px;
}

.dgps-ticket {
    padding: 8px;
    display: flex;
    gap: 4px;

    .ticket-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .ticket-header {
        display: flex;
        justify-content: space-between;

        .tractor-name {
            @extend .ticketFontStyle;
            font-weight: 600;
        }

        .created-time {
            @extend .ticketFontStyle;
            font-weight: 400;
        }

        .created-time {
            color: #99999C;
        }

        .ticket-name {
            color: #333333;
        }
    }

    .ticket-desc {
        @extend .ticketFontStyle;
        font-weight: 400;
        color: #333333;
    }
}

.analytic-reports-camera-container {
    display: flex;
    align-items: center;

    .dgps-reports-section {
        display: flex;
    }

    .dgps-camera-section {
        display: flex;
        gap: 8px;
    }

}


/* Digital Pacakge modal Styles */


/* Welcome Modal Styles */

.welcomeModalFontStyles {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0%;
}

.welcome-modal-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome-modal {
    display: flex;
    flex-direction: column;
    width: 768px;
    border-radius: 12px;
    padding: 28px 0px;
    gap: 24px;
    position: absolute;
    background-color: #fff;

    .welcome-modal-header {
        display: flex;
        gap: 12px;
        // justify-content: space-between;
        align-items: center;
        padding-right: 40px;
        padding-left: 40px;
        padding-bottom: 24px;
        border-bottom: 1px solid #EEEEEE;

        .welcome-modal-title {
            font-weight: 600;
            font-size: 20px;
            @extend .welcomeModalFontStyles
        }
    }

    .welcome-modal-content {
        display: flex;
        flex-direction: column;
        padding: 0px 32px;
        gap: 24px;


        .welcome-modal-description {
            color: #5C5C5C;
            @extend .welcomeModalFontStyles
        }

        .welcome-modal-content-img-block {
            text-align: center;
        }

    }

    .welcome-modal-list {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
    }

    .welcome-modal-list-item {
        display: flex;
        width: 340px;
        height: 74px;
        border-radius: 6px;
        gap: 6px;

        .list-item-content {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .list-item-title {
                font-weight: 500;
                font-size: 14px;
                color: #5C5C5C;
                @extend .welcomeModalFontStyles
            }

            .list-item-description {
                font-weight: 400;
                font-size: 14px;
                color: #99999C;
                @extend .welcomeModalFontStyles
            }
        }
    }

    .welcome-modal-footer {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        padding: 0px 40px;
    }
}

/* Welcome Modal Styles */